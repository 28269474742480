$body-background-color: #58585b;

// TODO delete alternatives
$design-blue: #0071bc;
$bronze-orange: #c7922d;
$copper-blue: #4499cc;
$copper-blue-hover: #45a9dc;

$primary: $copper-blue; //design-blue = #0071BC //orange = #c7922d //alt-blue = #4499cc
$primary-hover: $copper-blue-hover;

$secondary: #59585c;
$secondary-hover: #69686c;

$warning: orange; //customise
$success: green; //customise
//$info: red;

$danger: #91251d;
$danger-hover: #a1251d;

$invalid: #ff4c4a;

$light: #838383;
$light-hover: #939393;

$dark: #59585c;
$dark-2: #525155;
$dark-hover: #69686c;

$font-size: 16px;
$font-family: Arial, sans-serif;

$body-color: #eee;
$body-color-hover: #fff;
$body-color-disabled: #999;

////
// Button Variables
////
$button-border-radius: 0;
$button-font-weight: bold;

$button-primary-background: $primary;
$button-primary-background-hover: $primary-hover;
$button-primary-border: $primary;
$button-primary-color: #fff;

$button-secondary-background: $secondary;
$button-secondary-background-hover: $secondary-hover;
$button-secondary-border: $secondary;
$button-secondary-color: #fff;

$button-danger-background: $danger;
$button-danger-background-hover: $danger-hover;
$button-danger-border: $danger;
$button-danger-color: #fff;

$header-button-color: #fff;
$header-button-color-hover: #ccc;

////
// Border Variables
////
$border-light: #cdcdcd;
$border-dark: #292929;

////
// Form Variables
////
$form-control-background: $dark;
$form-control-background-hover: $dark-hover;
$form-control-background-focus: $dark-2;

$form-control-border-color: $border-light;
$form-control-border-radius: 0;

$form-control-padding: 0.375rem 0.75rem;

$form-control-placeholder: #aaa;

////
// Card Variables
////
$card-color: #838383;
$card-color-accent: $dark;
$card-color-dark: #323232;
$card-color-disabled: #5f6569;

$box-shadow: none;
$box-shadow-secondary: 0px 2px 8px 3px $dark;

/* You can add global styles to this file, and also import other style files */

@import "./styles/global-vars";
@import "./styles/material-theme";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/scss/bootstrap";
// @import "bootstrap/scss/mixins/breakpoints";
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/dist/themes/light.css";
@import "../node_modules/bootstrap/scss/mixins/breakpoints";

@media (min-width: 1600px) {
    .container {
        max-width: 1580px;
    }
}

body {
    text-shadow: none;
    font-family: $font-family !important;
    font-size: $font-size !important;
    color: $body-color;
    background-color: $body-background-color;
}

a {
    color: $body-color;
    font-weight: bold;

    &:not(.disabled) :hover {
        color: $body-color-hover;
        text-decoration-color: $body-color-hover;
    }
}

.spinner {
    width: 160px;
    margin: auto;
}

.loading-bar {
    height: 20px;
    border: 1px solid $border-light !important;
    color: #eee;
}

.top-right-tt {
    position: absolute;
    right: 6px;
    top: 0;
}

.pull-right {
    float: right;
}

button:disabled {
    cursor: not-allowed;
}

.shadow-default {
    box-shadow: $box-shadow;
}

.inc-deleted {
    background-color: $card-color-accent;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary !important;
}
.custom-control-label::after {
    border: 1px solid #aaa;
    border-radius: 0.25em;
}

////
// Card Styles
////

.card,
.modal-content {
    background-color: $card-color !important;
}

.card-row.row > [class*="col-"] {
    margin-bottom: 0.5rem;

    @media (min-width: 992px) {
        padding-left: 4px;
        padding-right: 4px;

        &:first-child {
            padding-left: 15px;
        }
        &:last-child {
            padding-right: 15px;
        }
    }
}

.card h3 {
    font-weight: normal !important;
}

.dropdown-menu {
    left: inherit;
    right: 0;
    transform: translate(0px, -1px);
    margin-top: 0;
    border-top: 0;
    background-color: $light;
    box-shadow: $box-shadow-secondary;

    .dropdown-item {
        text-shadow: none;
        cursor: pointer;

        &:hover {
            color: $body-color-hover;
            background-color: $light-hover;
        }
        &.disabled {
            color: $body-color-disabled;
            cursor: not-allowed;
        }

        span > svg,
        i {
            margin-top: -0.4rem;
        }
    }

    &.dark {
        background-color: #424242;

        .dropdown-item:hover {
            color: $body-color-hover;
            background-color: #525252;
        }
    }
}

////
// Modal Styles
////

.modal-backdrop.show {
    z-index: 10010;
}
.modal.show {
    z-index: 10020;
}
.modal-content {
    padding: 0.5rem;
}

.modal-dialog.modal-xl {
    @media (min-width: 768px) {
        width: 740px;
        max-width: 740px;
    }
    @media (min-width: 992px) {
        width: 960px;
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        width: 1120px;
        max-width: 1120px;
    }
    @media (min-width: 1600px) {
        width: 1458px;
        max-width: 1458px;
    }
}

.modal-button-row {
    margin-top: 0.5rem;
    justify-content: space-between;
}

////
// Date input styles
////

.cdk-overlay-container {
    z-index: 10100 !important;

    .owl-dialog-container {
        overflow: hidden;

        .owl-dt-calendar {
            max-width: 350px;
            max-height: 360px;
        }
        .owl-dt-timer {
            max-width: 350px;
        }
    }
}
.calendar-icon {
    position: absolute;
    top: 13px;
    right: 15px;
    pointer-events: none;
}
.calendar-icon-alt {
    position: absolute;
    top: 13px;
    right: 111px;
    pointer-events: none;
}

////
// Button styles
////

button.btn {
    border-radius: $button-border-radius;
    font-weight: $button-font-weight;
}

button.btn.btn-primary {
    background-color: $button-primary-background;
    border: 1px solid $button-primary-border;
    color: #fff;

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):active {
        background-color: $button-primary-background-hover !important;
        border: 1px solid $button-primary-border;
    }
    &:disabled {
        opacity: 0.5;
    }
}
button.btn.btn-secondary {
    background-color: $button-secondary-background;
    border: 1px solid $button-secondary-border;

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):active {
        background-color: $button-secondary-background-hover !important;
        border: 1px solid $button-secondary-border;
    }
    &:disabled {
        opacity: 0.5;
    }
}

button.btn.btn-danger {
    background-color: $button-danger-background;
    border: 1px solid $button-danger-border;

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):active {
        background-color: $button-danger-background-hover !important;
        border: 1px solid $button-danger-border;
    }
    &:disabled {
        opacity: 0.5;
        background-color: #444;
        border: 1px solid rgba(0, 0, 0, 0.6);
    }
}

svg.icon,
i.icon,
svg.fa-ellipsis-v,
i.fa-ellipsis-v {
    color: $body-color-hover;
}

////
// Form control styles
////
input.form-control,
textarea.form-control,
select.form-control,
div.form-control {
    background-color: $form-control-background;
    border-radius: $form-control-border-radius;
    border-color: $border-light;
    color: $body-color;
    padding: $form-control-padding;
    height: calc(2.90625rem + 2px);
    display: flex;
    align-items: center;

    &::placeholder {
        color: $form-control-placeholder;
    }

    &:hover {
        background-color: $form-control-background-hover;
        color: $body-color-hover;
    }
    &:focus {
        background-color: $form-control-background-focus;
        color: $body-color-hover;
        border-color: $border-light;
    }
    &:disabled {
        background-color: #6e6e70;
        color: $body-color-disabled;
        cursor: not-allowed;
    }
}

ng-select {
    border-radius: $form-control-border-radius;

    .above {
        z-index: 5;
    }

    &.h-100 > div {
        height: 100%;
    }

    &.ng-invalid.ng-touched > div {
        border-color: $invalid !important;
    }

    > div {
        // Gives ng-select the same appearance as a form-control
        display: flex;
        align-items: center;
        width: 100% !important;
        height: calc(2.90625rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: $body-color !important;
        background-color: $form-control-background !important;
        background-clip: padding-box;
        border: 1px solid $form-control-border-color !important;
        border-radius: $form-control-border-radius;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            background-color: $form-control-background-hover;
        }

        .value,
        .placeholder {
            line-height: inherit !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
        }
        .placeholder {
            color: $form-control-placeholder !important;
        }
        .toggle {
            margin-top: -2px;
            line-height: 36px !important;
            &:hover {
                background-color: transparent !important;
            }
        }
        .multiple {
            padding: 0 !important;
            input::placeholder {
                color: $form-control-placeholder;
            }

            .option {
                margin: 0 2px !important;
                margin-top: 0.25rem !important;
                margin-bottom: 0.25rem !important;
                height: 24px;
                background-color: $button-primary-background !important;
                border: 1px solid $button-primary-border !important;
                color: #fff !important;

                > .deselect-option {
                    color: #ddd !important;

                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .form-group .col-form-label {
        width: 100%;
    }
}

.form-group.row {
    align-items: center;
}

.mandatory {
    color: $invalid;
}

.form-control.ng-invalid.ng-touched {
    border-color: $invalid;
}

.form-control-error {
    color: $invalid;
}

.text-invalid {
    color: $invalid;
}

input.form-control:read-only {
    cursor: default;
    background-color: $disabled-input !important;
    color: $body-color !important;
    &:hover {
        background-color: $disabled-input !important;
        color: $body-color !important;
        //border: 1px solid rgba(0, 0, 0, 0.5) !important;
    }
}

.input-group {
    box-shadow: $box-shadow;
    border-radius: $form-control-border-radius;

    > *:not(.input-group-append) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
    }
}

select.form-control:not(:disabled) {
    cursor: pointer !important;
}
select.form-control {
    outline: none;
}

select-dropdown > div {
    color: #52575c !important;
}

div.form-control.readonly {
    background-color: $disabled-input !important;
    color: $body-color !important;
    &:hover {
        background-color: $disabled-input !important;
        color: $body-color !important;
    }
}

////
// Table styles
////

table,
table tr,
table tr th,
table tr td {
    border-color: $border-dark !important;
}

.table-dark-striped {
    .list-row,
    tbody tr {
        &:hover:not(.not-selectable) {
            transition: background-color 0.2s;
            background-color: $dark-hover !important;
        }
        &:nth-child(even) {
            background-color: $dark-2;
        }
        &:nth-child(odd) {
            background-color: $dark;
        }
    }
}

////
// Material themes
////

$mat-primary: $primary;
$mat-secondary: $primary-hover;

$mat-dark: #424242;

mat-card.dark {
    background-color: $mat-dark;
}

// Toggle switch
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $mat-primary;
}

.mat-mdc-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $mat-secondary;
}

// slider
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-accent .mat-slider-track-fill {
    background-color: $mat-primary;
}

// checkbox
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $mat-primary;
}

.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent
    .mat-checkbox-ripple
    .mat-ripple-element {
    background-color: $mat-secondary;
}

.mat-checkbox-layout {
    margin-bottom: 0;
    .mat-checkbox-label {
        margin-top: 1px;
        font-family: $font-family;
    }
}
.mat-pseudo-checkbox::after {
    color: $body-color;
}

// form fields
.mat-select-value,
.mat-select-arrow,
.mat-form-field-appearance-legacy .mat-form-field-label {
    color: $body-color;
}
.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-form-field-ripple {
    background-color: $body-color;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-mdc-form-field-error {
    color: $body-color;
    font-weight: bold;
}

mat-form-field textarea.mat-mdc-input-element {
    overflow-y: hidden;
}

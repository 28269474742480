// @import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use "@angular/material" as mat;

@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$copper-blue: (
    50: #e9f3f9,
    100: #c7e0f0,
    200: #a2cce6,
    300: #7cb8db,
    400: #60a8d4,
    500: #4499cc,
    600: #3e91c7,
    700: #3586c0,
    800: #2d7cb9,
    900: #1f6bad,
    A100: #e6f3ff,
    A200: #b3d9ff,
    A400: #80c0ff,
    A700: #67b3ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$mat-black: (
    50: #e6e6e6,
    100: #c2c2c2,
    200: #999999,
    300: #707070,
    400: #515151,
    500: #323232,
    600: #2d2d2d,
    700: #262626,
    800: #1f1f1f,
    900: #000000,
    A100: #ef6c6c,
    A200: #ea3e3e,
    A400: #f50000,
    A700: #db0000,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$red-palette: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #f44336,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$copper-dark-primary: mat.define-palette($copper-blue, 100);
$copper-dark-accent: mat.define-palette($copper-blue);

// The warn palette is optional (defaults to red).
// $copper-dark-warn: mat.define-palette($red-palette);
$custom-typography: mat.define-typography-config(
    $font-family: "Arial",
);
// Create the theme object (a Sass map containing all of the palettes).
$copper-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: $copper-dark-primary,
            accent: $copper-dark-accent,
        ),
        typography: $custom-typography,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($copper-dark-theme);
@include mat.all-component-themes($copper-dark-theme);

// @include angular-material-typography($custom-typography);

//////
//// Material themes
//////

.mat-drawer-container {
    background-color: $body-background-color;
}

.mat-toolbar {
    background-color: #424242;
}

// Toggle switch

// slider

// checkbox
.mat-checkbox-layout {
    margin-bottom: 0;
    .mat-checkbox-label {
        margin-top: 1px;
    }
}
.mat-checkbox-checked .mat-checkbox-checkmark-path {
    stroke: white !important;
}

// form fields
.mat-mdc-input-element:read-only {
    color: #ccc;
    cursor: not-allowed;
}

.mdc-text-field {
    background-color: $card-color !important;
    color: $body-color !important;
}

// tables
.mat-mdc-table {
    color: $body-color;
    border-color: $border-dark;
    background-color: inherit;
    .mat-mdc-row {
        border-bottom: 1px solid black !important;
    }
    .mat-mdc-row:hover {
        background-color: #999999 !important;
        cursor: pointer;
    }

    .mat-mdc-header-cell {
        color: $body-color;
        border-color: $border-light;
        border-bottom: 2px solid;
        font-size: $font-size;
        font-weight: bold;
    }
    .mat-sort-header-arrow {
        color: $body-color;
    }
    .mat-mdc-cell {
        color: $body-color;
        border-color: $border-light;
        font-size: $font-size;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

//paginator
.mat-mdc-paginator {
    color: $body-color;
    background-color: inherit;
}

.mat-mdc-card {
    color: $body-color;
    background-color: $card-color !important;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
}

.mat-mdc-card-title {
    color: $body-color;
    width: calc(100% - 30px);
}
